<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        color="color3"
        text
        v-on="on"
        small
      >
        {{myMode}} Event
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="color1 color1Text--text">
        <v-toolbar-title>Are you sure?</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pt-3">
        <h3>Are you sure you want to {{myMode.toLowerCase()}} this event?</h3>
        <div v-if="myMode === 'Delete'">You will lose all access to it.</div>
      </v-card-text>
      <v-card-actions>
        <v-row dense justify="center">
          <v-btn color="success" @click.stop="go" :loading="loading" class="mr-3">Yes</v-btn>
          <v-btn color="error" @click.stop="dialog=false" :disabled="loading">No</v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['mode', 'tournament', 'isEvent'],
  data () {
    return {
      dialog: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['user']),
    myMode () {
      return this.mode === 'Delete' ? this.mode : this.tournament.statusId === 101 ? 'Un-cancel' : 'Cancel'
    },
    statusId () {
      return this.mode === 'Delete' ? 999 : this.tournament.statusId === 101 ? 0 : 101
    },
    backToUsername () {
      return this.isEvent ? this.tournament.organizers.length && this.tournament.organizers[0].organization.username : this.tournament.organization.username
    }
  },
  methods: {
    go () {
      this.loading = true
      const dto = {
        id: this.tournament.id,
        statusId: this.statusId
      }
      if (this.isEvent) {
        this.patchSeries(dto)
      } else {
        this.patchTournament(dto)
      }
    },
    patchTournament (dto) {
      this.$VBL.tournament.patch(dto)
        .then((response) => {
          if (this.mode === 'Delete') {
            this.$router.push({ name: 'organization-home', params: { username: this.backToUsername } })
          } else {
            this.dialog = false
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    patchSeries (dto) {
      this.$VBL.post.series(dto)
        .then((response) => {
          if (this.mode === 'Delete') {
            this.$router.push({ name: 'organization-home', params: { username: this.backToUsername } })
          } else {
            this.dialog = false
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
